/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

const images = require.context('../theme/images', true)

require("trix")
require("@rails/actiontext")

import 'jquery'
import 'theme'
import 'dashboard'
import 'controllers/shared'
import 'controllers/dashboard'

window.$ = window.jQuery = require('jquery')

import { marked } from 'marked'
marked.use({
  mangle: false,
  headerIds: false
})
window.marked = marked

window.katex = require('katex')
import {} from 'katex/dist/katex.css'


window.Croppr = require('croppr')
import {} from 'croppr/src/css/croppr.css'

import TestSessionCode from 'online_testing/test_session_code'
window.TestSessionCode = TestSessionCode

// error reporting
import { appsignal } from "vendor/appsignal"
window.appsignal = appsignal
