import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    this.pair_regex = new RegExp(/^(-?\d+=\d+\n*)*$/)
  }

  process() {
    let value = this.element.value.trim()

    if (value.length == 0) {
      this.element.style.borderColor = ""
      this.element.style.backgroundColor = ""
    } else if (this.check(value)) {
      this.element.style.borderColor = "green"
      this.element.style.backgroundColor = '#fff'
    } else {
      this.element.style.borderColor = "red"
      this.element.style.backgroundColor = '#fcc'
    }
  }

  check(value) {
    if (this.element.dataset.mode == "grid"){
      // split into rows and columns
      let lines = value.split("\n")
      let grid = lines.map(line => line.split(" "))

      // ensure more than 1 row, and same number of items in each row
      let outcome = grid.length > 1 && [...new Set(grid.map(scores => scores.length))].length == 1

      return outcome

    } else {
      return this.pair_regex.test(this.element.value)
    }
  }
}
