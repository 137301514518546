import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'togglee' ]
  static values = { class: String }

  hide(e) {
    if (!e.target.checked && !e.target.selected) { return; }

    this.toggleeTargets.forEach((item) => {
      this.hide_item(item)
    });
  }

  show(e) {
    if (!e.target.checked && !e.target.selected) { return; }

    this.toggleeTargets.forEach((item) => {
      this.show_item(item)
    });
  }

  toggle(e) {
    e.preventDefault()
    e.stopPropagation()

    this.toggleeTargets.forEach((item) => {
      this.toggle_item(item)
    });
  }

  switch(e) {
    this.toggleeTargets.forEach((item) => {
      if (item.dataset.switch == e.target.value) {
        this.show_item(item)
      } else {
        this.hide_item(item)
      }
    })
  }

  toggle_class() {
    return this.classValue || 'hide'
  }

  toggle_item(item) {
    item.classList.toggle(this.toggle_class());
    if ("togglerFocus" in item.dataset && !item.classList.contains(this.toggle_class())) {
      let input = item.querySelector('input[type="text"], textarea')
      if (input) {
        input.focus()
        const temp_val = input.value
        input.value = ''
        input.value = temp_val
        input.scrollTop = 99999
      }
    }
  }

  show_item(item) {
    item.classList.remove(this.toggle_class());
  }

  hide_item(item) {
    item.classList.add(this.toggle_class());
  }
}
