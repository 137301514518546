import 'css.escape'
import 'foundation-sites'
import './foundation.scss'
import './actiontext.scss'

$(document).ready(function() {
  $(document).foundation()
})

// prevent unintential value change when scrolling over a focussed number input
document.addEventListener("wheel", function(event) {
  if(document.activeElement.type === "number"){
    document.activeElement.blur()
  }
})
