import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["boundaries", "total", "medium"]

  static values = {
    tags: Array
  }

  connect() {
    this.updateBoundaries()
  }

  // given a boundaries container and a boundary, insert a checkbox for that boundary
  insertBoundaryCheckBox(boundary) {
    const checkbox = document.createElement("input")
    checkbox.setAttribute("type", "checkbox")
    checkbox.setAttribute("value", boundary)
    const name = this.element.dataset.name + '[adaptive_boundaries][]'
    checkbox.setAttribute("name", name)
    checkbox.setAttribute("checked", true)
    checkbox.style.display = "none"

    this.element.appendChild(checkbox)
  }

  updateBoundaries() {
    const boundaryList = this.boundariesTarget.value

    let boundaries = []
    if (boundaries = boundaryList.match(/\s*(\d+)\s*,\s*(\d+)\s*/)) {
      this.tagsValue = ["easy", "medium", "hard"]
    } else if (boundaries = boundaryList.match(/\s*(\d+)\s*/)) {
      this.tagsValue = ["easy", "hard"]
    } else {
      this.tagsValue = []
      this.updateTotals()
      return
    }

    // record boundary values
    // boundaries.slice(1).forEach(boundary => this.insertBoundaryCheckBox(boundary))

    // toggle medium boundary state
    const disableMedium = (this.tagsValue.length == 2)
    this.mediumTargets.forEach(element => element.disabled = disableMedium)

    // update medium?
    this.updateTotals()
  }

  updateTotals() {
    this.totalTargets.forEach(total => {
      let count = this.element.querySelectorAll('input[value="' + total.dataset.tag + '"]:checked').length

      if (total.dataset.tag == "medium" && this.tagsValue.length == 2) {
        // disabled
        count = "-"
      }

      total.textContent = count
    })
  }
}
