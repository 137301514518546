import { Controller } from "stimulus"
import Userback from '@userback/widget'

export default class extends Controller {
  connect() {
    // immediate setup if no shortcut defined
    if (this.element.dataset.userbackKey === undefined) {
      this.setup()
    }
  }

  keyup(e) {
    // initialise the library on demand
    if (e.ctrlKey && e.key === this.element.dataset.userbackKey) {
      this.setup()
      this.element.dataset.action = "" // prevent re-fire
    }
  }

  setup() {
    const token = this.element.dataset.userbackToken

    if (token !== undefined) {
      Userback(token, {
        name: this.element.dataset.userbackName,
        email: this.element.dataset.userbackEmail
      })
    } else {
      console.error("Missing token for Userback")
    }
  }
}
