import { Controller } from "stimulus"
import Selectable from "selectable.js"

export default class extends Controller {
  static targets = [ "grid", "cell", "output" ]
  static classes = [ "selected" ]

  static values = {
    state: String,
    raw: Array,
    cells: Object
  }

  connect() {
    // ensure initial state
    if (this.stateValue == "") { this.stateValue = "available" }

    this.selectable = new Selectable({
      appendTo: this.gridTarget,
      filter: this.cellTargets,
      toggle: true
    })

    this.selectable.controller = this
    // this.selectable.on('selecteditem', this.selected)
    // this.selectable.on('deselecteditem', this.deselected)
    this.selectable.on('end', this.end)

    this.connected = true
  }

  disconnect() {
    this.connected = false
  }

  // NOTE: executed in Selectable context, ie. to access
  // the Stimulus controller use `this.controller`
  end(e, selected, deselected) {
    let cells = this.controller.cellsValue

    // invert selection if first cell is already selected
    if (selected.length > 1 && e.target.dataset.state) {
      deselected = selected
      selected = []
    }

    for (const item of selected) {
      const cell = item.node
      cells[cell.dataset.x] ||= {}
      cells[cell.dataset.x][cell.dataset.y] = this.controller.stateValue
    }

    for (const item of deselected) {
      const cell = item.node

      if (cells[cell.dataset.x] && cells[cell.dataset.x][cell.dataset.y]) {
        delete cells[cell.dataset.x][cell.dataset.y]
      }
    }

    this.controller.cellsValue = cells
  }

  changeState(e) {
    this.stateValue = e.target.value
  }

  // usually only runs on load
  rawValueChanged() {
    let cells = {}

    for (const [_, raw] of Object.entries(this.rawValue)) {
      cells[raw.x] ||= {}
      cells[raw.x][raw.y] = raw.availability
    }

    this.cellsValue = cells
  }

  cellsValueChanged() {
    this.updateGrid()
    this.updateOutput()
  }

  updateGrid() {
    this.cellTargets.forEach(cell => {
      delete cell.dataset.state
      cell.classList.remove(this.selectedClass)

      if (this.cellsValue[cell.dataset.x] && this.cellsValue[cell.dataset.x][cell.dataset.y]) {
        cell.dataset.state = this.cellsValue[cell.dataset.x][cell.dataset.y]
        cell.classList.add(this.selectedClass)
      }
    })
  }

  updateOutput() {
    // don't update output during initial setup (unpredictable valueChanged() order)
    if (!this.connected || !this.hasOutputTarget) { return }

    let output = []
    for (const [x, rows] of Object.entries(this.cellsValue)) {
      for (const [y, availability] of Object.entries(rows)) {
        output.push({ x: x, y: y, availability: availability })
      }
    }

    const new_output = JSON.stringify(output)

    if (new_output != this.outputTarget.value) {
      this.outputTarget.value = new_output
      const change_event = new CustomEvent('change', { bubbles: true, cancelable: true })
      this.outputTarget.dispatchEvent(change_event)
    }

  }

  clear(e) {
    e.preventDefault()
    this.cellsValue = {}
  }
}
