import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["recipient"]

  add(e) {
    let target = e.currentTarget
    const recipients = JSON.parse(target.dataset.ids)

    // select new recipients
    this.recipientTargets.forEach ((option) => {
      if (recipients.indexOf(parseInt(option.value)) >= 0) {
        option.selected = true
      }
    })

    // trigger change event
    const change_event = new CustomEvent('change', { bubbles: true, cancelable: true })
    this.recipientTarget.dispatchEvent(change_event)
  }
}
