import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "staff", "timezone", "address", "parents", "students", "email", "details", "submit", "clientTemplate", "emailTemplate" ]
  static values = {
    staff: Array,
    parents: Object,
    students: Object,
    email: Number
  }

  connect() {
    this.updateForm()
  }

  selectAddress(e) {
    e.preventDefault()

    const option = e.target
    this.addressTarget.value = option.dataset.address
    this.timezoneTarget.value = option.dataset.zone

    this.updateForm()
  }

  selectEmail(e) {
    this.emailValue = e.target.value
    this.updateForm()
  }

  parentAdded(e) {
    const id = parseInt(e.target.value)

    if (id > 0 && !this.parentsValue[id]) {
      fetch('/clients/' + id + '.json', { headers: { "X-Requested-With": "XMLHttpRequest" }})
        .then(response => response.json())
        .then(parent => {
          let updated_parents = this.parentsValue
          updated_parents[parent.id] = parent
          this.parentsValue = updated_parents

          // add children
          let updated_students = this.studentsValue

          parent.children.forEach(child => {
            if (!this.studentsValue[child.id]) {
              updated_students[child.id] = child
            }
          })

          this.studentsValue = updated_students
        })
    }
  }

  studentAdded(e) {
    const id = parseInt(e.target.value)

    if (id > 0 && !this.studentsValue[id]) {
      fetch('/clients/' + id + '.json', { headers: { "X-Requested-With": "XMLHttpRequest" }})
        .then(response => response.json())
        .then(student => {
          let updated_students = this.studentsValue
          updated_students[id] = student
          this.studentsValue = updated_students

          // add parents
          let updated_parents = this.parentsValue

          student.parents.forEach(parent => {
            if (!this.parentsValue[parent.id]) {
              updated_parents[parent.id] = parent
            }
          })

          this.parentsValue = updated_parents
        })
    }
  }

  deleteClient(e) {
    const id = e.target.dataset.id

    if (this.parentsValue[id]) {
      let updated_parents = this.parentsValue
      delete updated_parents[id]
      this.parentsValue = updated_parents
    }

    if (this.studentsValue[id]) {
      let updated_students = this.studentsValue
      delete updated_students[id]
      this.studentsValue = updated_students
    }
  }

  parentsValueChanged() {
    this.updateClientLists()
    this.updateEmailList()
    this.updateForm()
  }

  studentsValueChanged() {
    this.updateClientLists()
    this.updateForm()
  }

  updateStaff() {
    this.staffValue = Array.from(this.staffTarget.querySelectorAll('input:checked')).map(input => input.value)
    this.updateForm()
  }

  updateClientLists() {
    if (!this.hasStudentsTarget) { return }

    // students
    this.studentsTarget.innerHTML = ''

    for (const [id, data] of Object.entries(this.studentsValue)) {
      let row = this.clientTemplateTarget.content.cloneNode(true)
      row = this.mergeClient(row, data)
      this.studentsTarget.appendChild(row)
    }

    // parents
    this.parentsTarget.innerHTML = ''

    for (const [id, data] of Object.entries(this.parentsValue)) {
      let row = this.clientTemplateTarget.content.cloneNode(true)
      row = this.mergeClient(row, data)
      this.parentsTarget.appendChild(row)
    }
  }

  updateEmailList() {
    if (!this.hasEmailTarget) { return }

    this.emailTarget.innerHTML = ''

    // redraw list of email inputs
    for (const [id, data] of Object.entries(this.parentsValue)) {
      let row = this.emailTemplateTarget.content.cloneNode(true)
      row = this.mergeEmail(row, data)
      this.emailTarget.appendChild(row)
    }

    // persist checked state of previously selected parent if possible
    if (this.emailValue > 0) {
      if (Object.keys(this.parentsValue).map(id => parseInt(id)).indexOf(this.emailValue) == -1) {
        this.emailValue = 0
      } else {
        this.emailTarget.querySelector('input[value="' + this.emailValue + '"]').checked = true
      }
    }
  }

  updateForm() {
    if (!this.hasDetailsTarget) { return }

    // update JSON hash
    let details = {
      students: Object.keys(this.studentsValue),
      parents: Object.keys(this.parentsValue),
      email_target: this.emailValue
    }

    this.detailsTarget.value = JSON.stringify(details)

    // update button state
    if (this.validForm()) {
      this.enable()
    } else {
      this.disable()
    }
  }

  validForm() {
    return this.staffValue.length > 0 && this.emailValue > 0 && this.addressTarget.value.trim().length > 0 && Object.keys(this.studentsValue).length > 0
  }

  label(data) {
    let label = data.name
    if (data.email.length > 0) { label = label + " (" + data.email + ")" }

    return label
  }

  mergeClient(row, data) {
    row.querySelector('tr').dataset.id = data.id
    row.querySelector('i').dataset.id = data.id
    row.querySelector('td').innerText = this.label(data)

    return row
  }

  mergeEmail(row, data) {
    row.querySelector('input').value = data.id
    row.querySelector('span').innerText = this.label(data)

    return row
  }

  enable() {
    if (!this.hasSubmitTarget) { return }

    this.submitTarget.disabled = false
  }

  disable() {
    if (!this.hasSubmitTarget) { return }

    this.submitTarget.disabled = true
  }
}
