import { Controller } from "stimulus"

export default class extends Controller {
  update(e) {
    const values = JSON.parse(e.target.closest('[data-date]').dataset.date)

    values.forEach((value, i) => {
      this.element.querySelector('select[name*="('+(i+1)+'i)"]').value = value
    })
  }
}
