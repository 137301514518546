import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'trigger', 'container' ]

  connect(e) {
    this.containerTarget.addEventListener('disabler:enabled', (e) => { this.recheck() })
    if (!this.enabled()) { this.update() }
  }

  recheck() {
    if (!this.enabled()) { return }

    // re-enable our elements too
    this.elements().forEach((element) => {
      if (!Array.from(this.descendant_containers()).some((container) => { return container.contains(element) })) {
        element.disabled = false
      }
    })
  }

  update() {
    // disable form inputs not inside other disablers
    this.elements().forEach((element) => {
      if (this.enabled()) {
        // only re-enable our elements
        if (!Array.from(this.descendant_containers()).some((container) => { return container.contains(element) })) {
          element.disabled = false
        }
      } else {
        // if trigger is not checked, disable all descendants
        element.disabled = true
      }
    })

    if (this.enabled()) {
      // trigger check for descendant controllers
      let event = new CustomEvent('disabler:enabled', { bubbles: false })

      this.descendant_containers().forEach((container) => {
        container.dispatchEvent(event)
      })

      if (this.hasContainerTarget) {
        this.containerTarget.classList.remove('disabled')
      }

    } else if (this.hasContainerTarget) {
      this.containerTarget.classList.add('disabled')
    }
  }

  // if multiple triggers can conflict, we can use this action to
  // disable all others once one has been checked. It must be unchecked to
  // re-enable all other triggers
  disableTriggers(e) {
    this.triggerTargets.forEach((trigger) => {
      if (trigger != e.currentTarget) {
        trigger.disabled = e.currentTarget.checked
      }
    })
  }

  enabled() {
    if (this.triggerTarget.dataset.invert){
      return !this.triggerTarget.checked
    } else {
      return this.triggerTarget.checked
    }
  }

  elements() {
    return this.containerTarget.querySelectorAll('input, select, textarea')
  }

  descendant_containers() {
    return this.containerTarget.querySelectorAll('[data-target="disabler.container"]')
  }
}
