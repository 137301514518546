import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "paste", "button", "answer", "desired", "questions", "question", "template", "number" ]

  updateCount() {
    const num_answers = this.newAnswers().length
    this.buttonTarget.textContent = "Fill " + num_answers + " Answer" + (num_answers == 1 ? "" : "s")
  }

  newAnswers() {
    let valid_answers = []
    this.pasteTarget.value.split(/\n+/).forEach((row) => {
      row = row.trim()
      if (row != "") {
        valid_answers.push(row)
      }
    })

    return valid_answers
  }

  updateAnswers() {
    if (!this.hasAnswerTarget) { return }

    const answers = this.newAnswers()

    this.answerTargets.forEach((field) => {
      let answer = answers.shift()
      if (answer === undefined) { return }

      field.value = answer
    })

    this.pasteTarget.value = ''
    this.buttonTarget.textContent = "Done!"
  }

  updateQuestions() {
    if (!this.hasQuestionTarget) { return }

    // partition questions
    const [persisted, unpersisted] = this.questionTargets.reduce(this.partitionPersisted, [[],[]])

    const desired_count = parseInt(this.desiredTarget.value)
    const persisted_count = persisted.length
    const unpersisted_count = unpersisted.length

    if (desired_count < persisted_count) {
      alert('This is less than the number of saved answers. Please use the "delete" checkboxes to remove existing answers')
      this.desiredTarget.value = this.desiredTarget.defaultValue

    } else {
      const change_count = desired_count - persisted_count - unpersisted_count

      if (change_count > 0) {
        this.addQuestions(change_count)
      } else if (change_count < 0) {
        this.removeQuestions(change_count)
      }

      this.desiredTarget.defaultValue = desired_count
    }
  }

  delete(e) {
    e.preventDefault()
    e.target.closest('tr').remove()
    this.desiredTarget.value = this.questionTargets.length
    this.desiredTarget.defaultValue = this.desiredTarget.value
  }

  partitionPersisted(result, element) {
    result[element.dataset.id ? 0 : 1].push(element)
    return result
  }

  ignoreReturn(e) {
    if (e.keyCode == 13) {
      e.preventDefault()
      e.stopPropagation()
      this.updateQuestions()
    }
  }

  highestNumber() {
    if (!this.hasQuestionTarget) { return 0 }

    return this.numberTargets.map((element) => { return element.value }).reduce((current, number) => { return Math.max(current, number) }, 0)
  }

  addQuestions(x) {
    const index = Date.now()
    const highest_number = this.highestNumber()
    const intermediate_template = document.createElement('template')

    for (let i=0; i<x; i++) {
      // get initial HTML
      let new_html = this.templateTarget.content.firstElementChild.cloneNode(true).outerHTML

      // ensure unique form names/ids
      const new_id = index + i
      new_html = new_html.replaceAll("][0][", "]["+new_id+"][")
      new_html = new_html.replaceAll("_0_", "_"+new_id+"_")

      // convert back to DOM element
      intermediate_template.innerHTML = new_html.trim()
      let new_element = intermediate_template.content.firstElementChild.cloneNode(true)

      // set question number
      const new_number = highest_number + i + 1
      new_element.querySelector('[data-answer-sheet-target="number"]').value = new_number

      this.questionsTarget.appendChild(new_element)
    }
  }

  removeQuestions(x) {
    if (x >= 0) { return }

    this.questionTargets.slice(x).forEach((element) => { element.remove() })
  }
}
